import Logo from "../../assets/img/logo.svg";
import xIcon from "../../assets/img/social/x.svg";
import telegramIcon from "../../assets/img/social/telegram.svg";
import discordIcon from "../../assets/img/social/discord.svg";

const Footer = () => {
  return (
    <>
      <div className="social">
        {/* X */}
        <a
          href="https://twitter.com/ProtocolMu"
          rel="noreferrer"
          target="_blank"
        >
          <img src={xIcon} alt="X" style={{ width: "24px", height: "auto" }} />
        </a>
        {/* Discord */}
        <a
          href="https://discord.gg/fNpq6wWr2w"
          rel="noreferrer"
          target="_blank"
        >
          <img src={discordIcon} alt="Discord" />
        </a>
        {/* Telegram */}
        <a href="https://t.me/mu_protocol" rel="noreferrer" target="_blank">
          <img src={telegramIcon} alt="Telegram" />
        </a>
      </div>

      <div className="footer">
        <a href="/">
          <img src={Logo} alt="MU Protocol" />
        </a>
        <div className="footer__menu">
          {/*}
          <a
            href="https://docs.google.com/document/d/1n82FGk9Bv0HXYnX8mbH2jbN0X2GaCijHwCDn85UtoEg/edit?usp=drivesdk"
            target="_blank"
            rel="noreferrer"
            className="footer--menu__item"
          >
            Litepaper
          </a>
          */}
          <a
            href="https://github.com/muprotocol"
            target="_blank"
            rel="noreferrer"
            className="footer--menu__item"
          >
            Github
          </a>
          {/*
          <a
            href="mailto:invest@petitcode.com"
            target="_blank"
            rel="noreferrer"
            className="footer--menu__item"
          >
            invest@petitcode.com
          </a>
          <a
            href="tel:+4930549065690"
            target="_blank"
            rel="noreferrer"
            className="footer--menu__item"
          >
            Phone
          </a>
          <a
            href="mailto:invest@petitcode.com"
            target="_blank"
            rel="noreferrer"
            className="footer--menu__item"
          >
            Request Pitch Deck
          </a>
          */}
        </div>
      </div>
    </>
  );
};

export default Footer;
