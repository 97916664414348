import { useState } from "react";

import SAccordion from "../../components/SAccordion";

const data = [
  {
    summary: "What is mu Protocol?",
    detial: (
      <p>
        mu Protocol is a next-generation blockchain interoperability layer that connects decentralized applications across multiple blockchains.
        It bridges the gap between isolated ecosystems, empowering developers and businesses to unlock the full potential of Web3.
      </p>
    ),
  },
  {
    summary: "How to get started?",
    detial: (
      <p>
        Explore Resources: Access our {" "}
        <a
          href="https://github.com/muprotocol"
          target="_blank"
          rel="noreferrer"
          className="link no-underline"
        >
          GitHub
        </a>{" "}
        Repository for SDKs, APIs, and guides.{" "}
        <br></br>
        Join the Community: Collaborate with developers on {" "}
        <a
          href="https://discord.com/invite/PgAX8zS2vu"
          target="_blank"
          rel="noreferrer"
          className="link no-underline"
        >
          Discord
        </a>{" "}
        or contribute to our ecosystem.

        <br></br>
        Start Building: Leverage our tools to deploy your dApp seamlessly across blockchains.
      </p>
    ),
  },
  {
    summary: "How to Become a Partner?",
    detial: (
      <p>
        Reach Out: Contact our partnerships team via info@muprotocol.io or the Contact Us page.
        <br></br>
        Share Your Vision: Let’s discuss your goals and how mu Protocol can help.
        <br></br>
        Collaborate: We’ll design and implement a custom multi-chain solution tailored to your needs.
      </p>
    ),
  },
];

export default function FAQ() {
  const [expanded, setExpanded] = useState(false);

  return (
    <section id="faq" className="faq grid gap-8">
      <div className="generic_title">FAQ</div>

      <div className="grid gap-4">
        {data.map((accordion, index) => {
          return (
            <SAccordion
              key={index}
              expanded={expanded}
              setExpanded={setExpanded}
              id={index + 1}
              summary={accordion.summary}
              detail={accordion.detial}
            />
          );
        })}
      </div>
    </section>
  );
}
