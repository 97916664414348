import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";

// Custom components
import ItemList, { ItemsTwoCol } from "../../components/ItemList";

const providersData = [
  {
    title: "Accelerate Your Vision",
    text: "Leverage mu Protocol to bring multi-chain capabilities to your projects.",
  },
  {
    title: "Custom Solutions",
    text: "Tailored integrations and full-stack support to fit your needs.",
  },
  {
    title: "Join a Thriving Ecosystem",
    text: "Partner with industry leaders and pioneers driving Web3 innovation.",
  },
];

const developersData = [
  {
    title: "Streamlined SDKs and APIs",
    text: " Focus on creating remarkable dApps while mu Protocol handles the heavy lifting of multi-chain integration.",
  },
  {
    title: "Cross-Chain Compatibility",
    text: "Deploy once, interact seamlessly across Ethereum, Solana, and more.",
  },
  {
    title: "Future-Ready Technology",
    text: "Scalable, secure, and designed for the next generation of decentralized applications.",
  },
];

const whyMuProtocolData = [
  {
    title: "Effortless Interoperability",
    text: "Break free from the constraints of single-chain ecosystems. mu Protocol ensures smooth interactions between blockchains, enabling true decentralization.",
  },
  {
    title: "Developer-Centric Design",
    text: "We simplify the Web3 onboarding journey with comprehensive documentation, robust APIs, and intuitive tools.",
  },
  {
    title: "Proven Expertise",
    text: "Built by blockchain veterans with a proven track record in delivering scalable, secure, and efficient solutions.",
  },
];

const Solutions = () => {
  const mTheme = useTheme();
  const isMobile = useMediaQuery(mTheme.breakpoints.down("md"));

  return (
    <div className="solutions">
      <div className="generic_title">The Solutions</div>

      <ItemsTwoCol isMobile={isMobile}>
        <ItemList
          contentType={"object"}
          title={"For Partners"}
          data={providersData}
        />
        <ItemList
          contentType={"object"}
          title={"For Developers"}
          data={developersData}
        />
        <ItemList
          contentType={"object"}
          title={"Why mu Protocol?"}
          data={whyMuProtocolData}
        />
      </ItemsTwoCol>
    </div>
  );
};

export default Solutions;
