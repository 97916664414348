import React from "react";
import { useNavigate } from "react-router-dom";
import { styled, Box } from "@mui/material";
import Header from "../../layouts/Header/Header";
import DotPatternImg from "../../assets/img/DotPattern.png";
import BlobImg from "../../assets/img/Blob.png";
import { ReactComponent as SendIcon } from "../../assets/icons/send.svg";
import Button from "@mui/material/Button";

export default function Error404() {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Header />
      <Content>
        <Title>404</Title>
        <SubTitle>What the mu?</SubTitle>
        <Oops>Oops you’re lost in space.</Oops>
        <CantFind>We can’t find the page that you’re looking for.</CantFind>
        <StyledButton
          variant="contained"
          startIcon={<SendIcon />}
          onClick={() => navigate("/")}
        >
          Go Home
        </StyledButton>
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #1e1f21;
  background-image: url(${DotPatternImg}), url(${BlobImg});
  background-repeat: repeat, no-repeat;
  background-size: 100%, 50%;
  background-position: center, bottom right;
  position: relative;
  @media only screen and (max-width: 800px) {
    background-size: 100%, 100%;
  }
`;

const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 20px;
`;

const Title = styled(Box)`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 250px;
  line-height: 300px;
  text-align: center;
  color: #19e4cc;
  @media only screen and (max-width: 800px) {
    font-size: 100px;
    line-height: 150px;
  }
`;

const SubTitle = styled(Box)`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 70px;
  line-height: 100px;
  text-align: center;
  color: #19e4cc;
  margin-top: -50px;
  @media only screen and (max-width: 800px) {
    font-size: 50px;
    line-height: 70px;
    margin-top: -30px;
  }
`;

const Oops = styled(Box)`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 52px;
  text-align: center;
  color: #ffffff;
  margin-top: 40px;
  @media only screen and (max-width: 800px) {
    font-size: 25px;
    line-height: 35px;
  }
`;

const CantFind = styled(Box)`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 42px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 50px;
  @media only screen and (max-width: 800px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 200px;
  min-width: max-content;
  height: fit-content;
  padding: 12px 32px;
  background: linear-gradient(89.71deg, #19e4cc 1.95%, #3894bb 99.75%);
  border-radius: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 20px;
  text-transform: none;

  & .MuiButton-startIcon {
    margin-right: 24px;
    transform: rotate(180deg);
  }
`;
