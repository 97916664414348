import React from "react";

export default function Intro() {
  return (
    <div className="intro">
      <div className="generic_title">Intro to mu</div>
      <div className="intro__content">
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/ELghGjEEJT8 "
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
}
