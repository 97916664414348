import Marquee from "react-fast-marquee";
import solanaLogo from "../../assets/img/partners/solanaLogo.png";
import labsLogo from "../../assets/img/partners/030LabsLogo.png";
import icpLogo from "../../assets/img/partners/internet-computer-icp-logo.svg";

const partnersLogos = [solanaLogo, labsLogo, icpLogo];

export default function PartnersMarquee() {
  return (
    <Marquee autoFill pauseOnHover speed={100}>
      {partnersLogos.map((logo, index) => (
        <img
          key={index}
          src={logo}
          alt={`Partner Logo ${index + 1}`}
          style={{
            width: "auto",
            height: "50px",
            margin: "0px 100px",
          }}
        />
      ))}
    </Marquee>
  );
}
